/* Typography */

a {
  color: var(--cyan);
  text-decoration: none;
}

a:hover {
  color: var(--grey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

h1,
h2 {
  font-size: 3rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-weight: 300;
  font-size: 1.5rem;
}
