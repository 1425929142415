:root {
  font-size: 16px;

  --sans-serif: "Source Sans Pro", sans-serif;

  --white: #ffffff;
  --black: #242424;
  --grey: #686868;
  --lightgrey: #d9d9d9;
  --cyan: #009fe3;
}

html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  background-color: var(--white);
  color: var(--black);
  font-family: var(--sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
}

/* ---------- Content ---------- */

/* Wrapper */

.container {
  max-width: 70rem;
  margin: 0 auto;
  padding: 2rem;
  display: grid;
  grid-gap: 1rem;
}

/* Logo */

.logo {
  width: 15rem;
}

@media (min-width: 48rem) {
  .logo {
    width: 20rem;
  }
}

/* Layout Base */

.content {
  display: grid;
  grid-gap: 2rem;
  grid-template-areas:
    "content-submenu"
    "content-text";
}

@media (min-width: 48rem) {
  .content {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "content-submenu content-text";
  }
}

.content__submenu {
  grid-area: content-submenu;
}

.content__text {
  grid-area: content-text;
}

/* Header */

header {
  display: flex;
  justify-content: space-between;
}

/* Breadcrumb */

ul.breadcrumb {
  list-style-type: none;
  margin: 0;
  padding: 0.75rem 0;
  border-top: solid 1px var(--lightgrey);
  border-bottom: solid 1px var(--lightgrey);
}

ul.breadcrumb li {
  display: inline;
}

ul.breadcrumb li a {
  color: var(--grey);
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
}

ul.breadcrumb li a:hover {
  color: var(--cyan);
}

ul.breadcrumb li + li:before {
  color: var(--grey);
  content: "/\00a0";
}

/* Submenu */

ul.submenu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.submenu li {
  padding: 0.5rem 0;
  border-bottom: solid 1px var(--lightgrey);
}

ul.submenu--child {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.submenu--child li {
  border: 0;
  padding-left: 1rem;
}
