/* Hero */

.hero {
  display: grid;
  grid-template-areas:
    "hero-image"
    "hero-slogan";
}

@media (min-width: 48rem) {
  .hero {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "hero-image hero-slogan";
  }
}

.hero__image {
  grid-area: hero-image;
  background-image: url("../img/2018-05-08-Banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
}

@media (min-width: 48rem) {
  .hero__image {
    height: auto;
  }
}

.hero__slogan {
  grid-area: hero-slogan;
  background-color: var(--cyan);
  color: var(--white);
  padding: 2rem 2.5rem;
}

@media (min-width: 48rem) {
  .hero__slogan {
    padding: 4rem 2.5rem;
  }
}

.hero__slogan__text {
  font-size: 1.35rem;
  font-weight: 300;
}

.hero__slogan__logo {
  width: 10rem;
}

.homecontent {
  display: grid;
  grid-gap: 2rem;
  grid-template-areas:
    "homecontent-main"
    "homecontent-news";
}

@media (min-width: 48rem) {
  .homecontent {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "homecontent-main homecontent-news";
  }
}

.home__main {
  grid-area: homecontent-main;
}

.home__main img {
  width: 10rem;
}

.home__news {
  grid-area: homecontent-news;
}

.newslist__wrapper {
  padding-bottom: 1rem;
}

.home__news__teaser {
  margin-top: 0;
}
