.nav {
  display: none;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.nav.showmenu {
  display: flex;
}

.nav.showmenu li {
  border-bottom: solid 1px var(--lightgrey);
  text-align: center;
  padding: 0.5rem 0;
}

.nav.showmenu li:last-child {
  border-bottom: 0;
  text-align: center;
  padding-top: 1rem;
}

@media (min-width: 50rem) {
  .nav {
    display: flex;
    flex-direction: row;
  }
}

.nav li a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
}

@media (min-width: 50rem) {
  .nav li a {
    margin-right: 1.5rem;
  }

  .nav li:last-child {
    margin-left: auto;
  }
}

@media (min-width: 50rem) {
  .hamburger {
    display: none;
  }
}

.search {
  padding: 0.5rem;
  border: solid 1px var(--lightgrey);
}
